@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  /* font-family: 'Courier New', Courier, monospace; */
  /* font-family: 'Verdana', monospace; */
  font-family: "Verdana", sans-serif;
  color: #222222;
}


@media (min-width: 640px){
  .container {
      max-width: 640px;
  }
}
@media (min-width: 768px){
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px){
  .container {
      max-width: 1024px;
  }
}
@media (min-width: 1280px){
  .container {
      max-width: 1280px;
  }
}
@media (min-width: 1536px){
  .container {
      max-width: 1536px;
  }
}
